import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { userHasRole, userHasPermission } from './common';

const RequireAuth = ({ roles = [], permissions = [], children }) => {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route, context.user]);

  const { userData } = useSelector((state) => state.User);

  const isPendingAuthorization = route === 'authenticated' && userData && Object.keys(userData).length === 0;

  // Check roles
  const hasRole = roles?.length > 0 ? roles.some((role) => userHasRole(role, userData)) : true;

  // Check permissions
  const hasPermission =
    permissions?.length > 0 ? permissions.some((permission) => userHasPermission(permission, userData)) : true;

  const hasAccess = hasRole && hasPermission;

  // Valid route states are: authenticated, setup, signIn, signOut, idle
  if (route === 'idle' || isPendingAuthorization) {
    return <CircularProgress />;
  } else if (route !== 'authenticated' && route !== 'idle') {
    // If we have setup, it means they were redirected there from a protected route
    // This means that they were once authenticated, otherwise there's no way they could have been there.
    // Effectively, this means their session expired.
    // Brand new signins come from the signIn route, or if the user explicitly signed out they get the signOut route
    let target = route !== 'setup' ? '/login' : '/login?status=expired';
    return <Navigate to={target} state={{ from: location, roles: roles, permissions: permissions }} replace />;
  } else if (route === 'authenticated' && hasAccess) {
    return <>{children}</>;
  } else {
    return <Navigate to="/" replace />;
  }
};

export default RequireAuth;
